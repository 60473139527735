@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap");
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: $primary-default;
}

h1 {
  font-size: 25px;
  font-weight: 600;
  line-height: 36px;
}

h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1.1px;
}

h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

h6 {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 10px;
  line-height: 1.2;
}

.lkm-logo {
  color: $orange;
  font-size: 30px;
}

.preformatted-text {
  white-space: pre-line;
}

.Content {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;

  img,
  iframe,
  embed,
  video,
  object {
    max-width: 100%;
    height: auto !important;
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h2 {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h4 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h5 {
    font-size: 15px;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h6 {
    font-size: 14px;
    letter-spacing: normal;
    line-height: 1.3;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.5;
  }
  a {
    font-size: inherit;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.5;
  }
}

// material-icons
.material-icons {
  vertical-align: middle;
  line-height: inherit;
  margin-top: -2px;
  color: inherit;
}

.btn-xs {
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.badge {
  font-size: 90%;
  line-height: 1.1;
  vertical-align: middle;
}

.text-label {
  font-size: 14px;
  font-weight: normal;
}

.material-icons.md-12 {
  font-size: 12px;
}

.material-icons.md-16 {
  font-size: 16px;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-32 {
  font-size: 32px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-64 {
  font-size: 64px;
}

.material-icons.md-72 {
  font-size: 72px;
}

.material-icons.md-84 {
  font-size: 84px;
}

.material-icons.md-96 {
  font-size: 96px;
}

.material-icons.md-120 {
  font-size: 120px;
}

//buttons
.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 0.825rem;
  line-height: 1.1;
  border-radius: 0.2rem;
}
