.MainHeader {
	.logo {
		color: #fc7307;
		font-size: 22px;
	}

	.dropdown-toggle::after {
		vertical-align: 0.1em;
	}
}
