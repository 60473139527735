.Packaging {
	.nav-link {
		display: block;
		border-radius: 0px;
		padding: 0.5rem 0;
		margin-right: 1.5rem;
	}
	.dropdown-toggle.active {
		// background-color: #f8f9fa;
		border-bottom: 4px solid #007bff;
	}

	code {
		font-size: 12px;
	}

	.btn-copy {
		cursor: pointer;
	}
	table {
		td {
			max-width: 240px;
			text-wrap: normal;
			word-wrap: break-word;
		}
	}
}
