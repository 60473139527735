@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.EntityDecorator {
  padding: 2px 0;
  background-color: #99ccff;
  border-left: 2px solid darken(#99ccff, 10%);
  border-right: 2px solid darken(#99ccff, 10%);
  &.confidence-low {
    background-color: lighten(#fc7307, 20%);
    border-left: 2px solid darken(#fc7307, 5%);
    border-right: 2px solid darken(#fc7307, 5%);
  }
  &.confidence-medium {
    background-color: #f3c51d;
    border-left: 2px solid darken(#f3c51d, 10%);
    border-right: 2px solid darken(#f3c51d, 10%);
  }
  &.confidence-high {
    background-color: lighten(#28a745, 20%);
    border-left: 2px solid darken(#28a745, 5%);
    border-right: 2px solid darken(#28a745, 5%);
  }
  blockquote {
    background-color: rgba(196, 196, 196, 0.3);
    border-left: 4px solid #b4b4b4;
    padding: 6px;
    margin-bottom: 12px;
  }

  blockquote.comment-unread {
    background-color: rgba(255, 229, 100, 0.3);
    border-left: 4px solid #ffe564;
  }

  &.has-unread-comment .unread-count-badge {
    margin-right: 5px;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: bold;
    background-color: red;
    color: white;
  }
}

.EntityDecoratorPopover p {
  font-size: 14px;
}

.EntityDecoratorPopover.popover {
  min-width: 400px;
  max-width: 600px;
  box-shadow: 0 0 5px darken(#fff, 8%);
}

.EntityDecoratorPopover .popover-body {
  max-height: 360px;
  overflow: scroll;
}

.EntityDecoratorPopover.popover textarea {
  min-width: 400px;
  max-width: 600px;
}

.ExhibitAnnotation {
  .alert-secondary {
    color: #091534;
    background-color: lighten(#7e7e7e, 40%);
    border-color: lighten(#7e7e7e, 30%);
  }
  &.annotator-backdrop {
    background-color: #f8f9fa;
    .public-DraftEditor-content {
      color: lighten(#000, 40%);
    }
  }
  .SavingStatus {
    position: sticky;
    top: 60px;
    float: right;
    z-index: 3;
  }

  .RichEditorControls-root {
    position: sticky;
    top: 60px;
    float: right;
    z-index: 3;

    .card {
      box-shadow: 0 4px 6px 0px rgba(0, 40, 100, 0.25);
      width: 480px;
      margin-top: 8px;
    }
    code {
      word-wrap: break-word;
      font-size: 12px;
    }
    .form-check-label {
      margin-bottom: -3px;
    }
    .form-check-inline {
      margin-right: 2rem;
    }

    .popover {
      margin-top: 5px;
      width: 480px;
      max-width: 480px;
      box-shadow: 0 4px 6px 0px rgba(0, 40, 100, 0.25);
    }
    .popover-body {
      max-height: 600px;
      overflow: scroll;
    }

    blockquote {
      background-color: rgba(196, 196, 196, 0.3);
      border-left: 4px solid #b4b4b4;
      padding: 6px;
      margin-bottom: 12px;
    }

    blockquote.comment-unread {
      background-color: rgba(255, 229, 100, 0.3);
      border-left: 4px solid #ffe564;
    }
  }

  // Draft
  .DraftEditor-editorContainer,
  .DraftEditor-root,
  .public-DraftEditor-content {
    font-family: "PT Serif", "Times New Roman", Times, Baskerville, Georgia,
      serif;
    font-size: 14px;
    height: inherit;
    text-align: initial;
  }
  .read-only {
    .DraftEditor-editorContainer,
    .DraftEditor-root,
    .public-DraftEditor-content {
      color: lighten(#000, 40%);
    }
  }
  .public-DraftEditor-content[contenteditable="true"] {
    -webkit-user-modify: read-write-plaintext-only;
  }
  .DraftEditor-root {
    position: relative;
  }
  .DraftEditor-editorContainer {
    background-color: rgba(255, 255, 255, 0);
    border-left: 0.1px solid transparent;
    position: relative;
    z-index: 1;
  }
  .public-DraftEditor-block {
    position: relative;
  }
  .DraftEditor-alignLeft .public-DraftStyleDefault-block {
    text-align: left;
  }
  .DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
    left: 0;
    text-align: left;
  }
  .DraftEditor-alignCenter .public-DraftStyleDefault-block {
    text-align: center;
  }
  .DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }
  .DraftEditor-alignRight .public-DraftStyleDefault-block {
    text-align: right;
  }
  .DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
    right: 0;
    text-align: right;
  }
  .public-DraftEditorPlaceholder-root {
    color: #9197a3;
    position: absolute;
    z-index: 1;
  }
  .public-DraftEditorPlaceholder-hasFocus {
    color: #bdc1c9;
  }
  .DraftEditorPlaceholder-hidden {
    display: none;
  }
  .public-DraftStyleDefault-block {
    position: relative;
    white-space: pre-wrap;
    margin-bottom: 10px;
  }
  .public-DraftStyleDefault-ltr {
    direction: ltr;
    text-align: left;
  }
  .public-DraftStyleDefault-rtl {
    direction: rtl;
    text-align: right;
  }
  .public-DraftStyleDefault-listLTR {
    direction: ltr;
  }
  .public-DraftStyleDefault-listRTL {
    direction: rtl;
  }
  .public-DraftStyleDefault-ol,
  .public-DraftStyleDefault-ul {
    margin: 16px 0;
    padding: 0;
    /*list-style-position: inside;*/
  }

  .public-DraftStyleDefault-ol li *,
  .public-DraftStyleDefault-ul li * {
    display: inline;
  }

  .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-left: 1.5em;
  }
  .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
    margin-right: 1.5em;
  }
  .public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
    margin-left: 3em;
  }
  .public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
    margin-right: 3em;
  }
  .public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
    margin-left: 4.5em;
  }
  .public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
    margin-right: 4.5em;
  }
  .public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
    margin-left: 6em;
  }
  .public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
    margin-right: 6em;
  }
  .public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
    margin-left: 7.5em;
  }
  .public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
    margin-right: 7.5em;
  }
  .public-DraftStyleDefault-unorderedListItem {
    list-style-type: square;
    position: relative;
  }
  .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
    list-style-type: disc;
  }
  .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
    list-style-type: circle;
  }
  .public-DraftStyleDefault-orderedListItem {
    list-style-type: none;
    position: relative;
  }
  .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    left: -36px;
    position: absolute;
    text-align: right;
    width: 30px;
  }
  .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
    position: absolute;
    right: -36px;
    text-align: left;
    width: 30px;
  }
  .public-DraftStyleDefault-orderedListItem:before {
    content: counter(ol0) ". ";
    counter-increment: ol0;
  }
  .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
    content: counter(ol1, lower-alpha) ". ";
    counter-increment: ol1;
  }
  .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
    content: counter(ol2, lower-roman) ". ";
    counter-increment: ol2;
  }
  .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
    content: counter(ol3) ". ";
    counter-increment: ol3;
  }
  .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
    content: counter(ol4, lower-alpha) ". ";
    counter-increment: ol4;
  }
  .public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
    counter-reset: ol0;
  }
  .public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
    counter-reset: ol1;
  }
  .public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
    counter-reset: ol2;
  }
  .public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
    counter-reset: ol3;
  }
  .public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
    counter-reset: ol4;
  }

  /* Add paddings on div/default blocks */
  .public-DraftEditor-content > [data-contents="true"] > div {
    margin: 0;
  }

  .text-left .public-DraftStyleDefault-block {
    text-align: left;
  }
  .text-right .public-DraftStyleDefault-block {
    text-align: right;
  }
  .text-center .public-DraftStyleDefault-block {
    text-align: center;
  }
  .text-justify .public-DraftStyleDefault-block {
    text-align: justify;
  }
}
