.table {
	td,
	th {
		vertical-align: middle;
		font-size: 90%;
	}

	thead {
		background-color: $gray-200;
		color: $black;

		th {
			border-bottom: 1px solid $gray-500;
			border-top: 1px solid $gray-500;
		}
	}

	&.table-sm {
		th {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
}
