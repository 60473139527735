/** The main css file includes custom css **/

/** custom
sass src/styles/index.scss src/index.css
poll for changes and automatically compiles
  sass --watch src/styles/index.scss:src/index.css
  #Allow you to include sass file directily into reactjs imports
  npm install node-sass --save
Install bootstrap sass
npm install bootstrap-sass
**/

@import "bootstrap";
@import "colors";
@import "typography";
@import "layout";
@import "cards";
@import "table";
@import "form";

body {
  margin: 0;
  padding: 0;
  padding-top: 60px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Main {
  display: flex;
  flex-direction: column;
  min-height: 94vh;
  .MainFooter {
    flex: 0 0 auto;
    background-color: #24252a;
  }
  .MainContent {
    flex: 1 0 auto;
    min-height: 85vh;
  }
  .MainHeader {
    flex: 0 0 auto;
  }
}
