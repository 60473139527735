/* Extra small devices (portrait phones, less than 576px)
  No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.container {
		padding-right: 0;
		padding-left: 0;
	}
}

/* Large devices (desktops, 992px and up) 
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.collapse,
.collapsing {
	width: 100%;
	margin: 0;
}

.badge-low {
	color: #1b0000;
	font-weight: 500;
	background-color: lighten(#fc7307, 20%);
}
.badge-medium {
	color: #1b0000;
	font-weight: 500;
	background-color: #f3c51d;
}
.badge-high {
	color: #1b0000;
	font-weight: 500;
	background-color: lighten(#28a745, 10%);
}

.well {
	background-color: whitesmoke;
	@extend .pt-3;
	@extend .pl-3;
	@extend .pr-3;
	@extend .pb-3;
	.nav-link {
		display: block;
		padding: 0.25rem 0.5rem;
	}
	&.well-secondary {
		.nav-link.active {
			color: darken(#007bff, 10%);
			background-color: darken(whitesmoke, 5%);
		}
	}
}

.Content {
	img,
	iframe,
	embed,
	video,
	object {
		max-width: 100%;
		height: auto !important;
		@extend .mt-3;
		@extend .mb-3;
	}
}
/* Medium devices (tablets, 992px and up) */
@media (min-width: 992px) {
	.WideView {
		.WideCanvas {
			.WideCenter {
				.FooterStickyRight {
					position: fixed;
					bottom: 16px;
					right: 18px;
					z-index: 100;
				}
			}
			.WideLeft {
				height: 98vh;
				position: -webkit-sticky; /* Safari */
				position: sticky;
				top: 0px;
				margin-top: -51px;
				padding-top: 51px;
				overflow: auto;
				border-right: 1px solid #f8f9fa;

				.FooterStickyLeft {
					position: fixed;
					bottom: 18px;
					left: 18px;
					z-index: 100;
				}
			}
			.WideRight {
				height: 98vh;
				position: -webkit-sticky; /* Safari */
				position: sticky;
				top: 0px;
				margin-top: -51px;
				padding-top: 51px;
				overflow: auto;
				border-right: 1px solid #f8f9fa;
				border-left: 1px solid #f8f9fa;
			}
		}
	}
}
