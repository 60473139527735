$body-background: #0000;
$primary-link: #2b70f6;

$primary-default: #1b0000;
$primary-dark: #091534;
$primary-neutral: #236ab9;
$primary-accent: #678ffe;
$primary-light: #d4e4f7;

$secondary-neutral: #fc7307;
$secondary-accent: #b85b14;

$tertiary-neutral: #012998;
$tertiary-success: #50d2c2;
$tertiary-emphasis: #d667cd;
$tertiary-warning: #fcab53;
$tertiary-info: #00b9ff;
$tertiary-danger: #ff3366;

$logo-color: #5861a6;
