.ExhibitDetails {
  .nav-tabs {
    position: sticky;
    top: 55px;
    z-index: 2;
    padding-top: 8px;
    margin: 0px -15px;
    background-color: #fff;
  }

  .document-raw {
    background-color: darken(#fff, 8%);
  }

  .document-annotation {
    box-shadow: 0 0 5px darken(#fff, 8%);
  }

  .document-entities {
    .table thead th {
      position: sticky;
      top: 105px;
      z-index: 1;
      background-color: #e9ecef;
      border-bottom: 1px solid #adb5bd;
      border-top: 1px solid #adb5bd;
    }
  }
}
